import {DATA_WILDCARDS, LANGUAGE} from "@/types/enums/frontend.enums";
import {Log} from "@/utils/log";
import {i_position, i_replacement} from "@/types/interfaces/frontend.interfaces";
import {evaluate} from "mathjs";
import {useWatch} from "@/services/watch.service";
import {StringUtils} from "@/utils/string.utils";
import {isConditional} from "@/types/typeguards/general.guards";
import {ref, Ref} from "vue";
import {i_text} from "@/types/interfaces/structure.interfaces";

const log = new Log("DataUtils");

export class DataUtils {
    public static MAX_WILDCARD_ITERATIONS = 20;

    public static REP_MATRIX:i_replacement[] = [
        {
            target: DATA_WILDCARDS.ZM_LAT,
            getter: () => 0
        },
        {
            target: DATA_WILDCARDS.ZM_LNG,
            getter: () => 0
        },
        {
            target: DATA_WILDCARDS.ZM_H,
            getter: () => StringUtils.padz(useWatch().getHours(), 2)
        },
        {
            target: DATA_WILDCARDS.ZM_M,
            getter: () => StringUtils.padz(useWatch().getMinutes(), 2)
        },
        {
            target: DATA_WILDCARDS.ZM_S,
            getter: () => StringUtils.padz(useWatch().getSeconds(), 2)
        },
        {
            target: DATA_WILDCARDS.ZM_YY,
            getter: () => (useWatch().getYear() + "").substring((useWatch().getYear() + "").length-2)
        },
        {
            target: DATA_WILDCARDS.ZM_YYYY,
            getter: () => StringUtils.padz(useWatch().getYear(), 4)
        },
        {
            target: DATA_WILDCARDS.ZM_MM,
            getter: () => StringUtils.padz(useWatch().getMonth(), 2)
        },
        {
            target: DATA_WILDCARDS.ZM_DD,
            getter: () => StringUtils.padz(useWatch().getDay(),2)
        },
        {
            target: DATA_WILDCARDS.ZM_SUN,
            getter: () => useWatch().getWeekday() == 1 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_MON,
            getter: () => useWatch().getWeekday() == 2 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_TUE,
            getter: () => useWatch().getWeekday() == 3 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_WED,
            getter: () => useWatch().getWeekday() == 4 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_THU,
            getter: () => useWatch().getWeekday() == 5 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_FRI,
            getter: () => useWatch().getWeekday() == 6 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_SAT,
            getter: () => useWatch().getWeekday() == 7 ? 1 : 0
        },
        {
            target: DATA_WILDCARDS.ZM_CALC,
            getter: (expression: string, source: any) => {
                try {
                    return evaluate(expression);
                } catch (e) {
                    log.error(`Error in calc expression: ${expression}: ${e}`);
                    return expression;
                }
            }
        },
        {
            target: DATA_WILDCARDS.ZM_REF,
            getter: (key: string, source: any) => {
                let defaultValue = key.includes(",") ? key.split(",")[1] : undefined;
                const value = DataUtils.getValueByPath(
                    key.includes(",") ? key.substring(0, key.indexOf(",")) : key,
                    source,
                    defaultValue !== undefined ? { skipWarnings: true } : {});
                if (value === undefined && defaultValue === undefined) {
                    log.warn("Cannot find reference with path " + key);
                    defaultValue = "";
                }
                //return value !== undefined ? value : "{{ZM_REF[" + key + "]}}";
                return value !== undefined ? value : defaultValue;
            }
        },
        {
            target: DATA_WILDCARDS.ZM_ROUND,
            getter: (expression: string, source: any) => {
                const flt_exp = parseFloat(expression);
                if (!isNaN(flt_exp)) {
                    return flt_exp.toFixed(2);
                } else {
                    log.error(`Cannot convert expression for rounding: ${expression}`);
                    return expression;
                }
            }
        },
        {
            target: DATA_WILDCARDS.ZM_EVAL,
            getter: (expression: string, source: any) => {
                try {
                    return eval(expression.replace(/\bAND\b/g, '&&').replace(/\bOR\b/g, '||'));
                } catch (e) {
                    log.error(`Error in eval expression: ${expression}: ${e}`);
                    return expression;
                }
            }
        }
    ]

    /**
     * Sets the value of an attribute in an object using a path to the attribute as reference.
     *
     * @param obj The object containing the attribute in question
     * @param path Path to the attribute, e.g. foo.bar[1].hello.world
     * @param value The value that should be assigned to the attribute
     * @returns An array of substrings split based on the provided criteria.
     */
    public static setValueByPath(obj: any, path: string, value: any): void {
        let currentObj = obj;
        if (path.substring(0,1) === "[") {
            path = "default" + path;
            currentObj = { default: currentObj };
        }
        const keys = path.split('.');

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (/\[\d+\]/.test(key)) {
                const match = key.match(/\d+/);
                if (match !== null) {
                    // Handle array notation, e.g., 'foo[0]'
                    const index = parseInt(match[0], 10);
                    if (!Array.isArray(currentObj[key.slice(0, key.indexOf('['))])) {
                        currentObj[key.slice(0, key.indexOf('['))] = [];
                    }
                    if (i === keys.length - 1) {
                        // Last key, set the value
                        currentObj[key.slice(0, key.indexOf('['))][index] = value;
                    } else {
                        // Traverse deeper into the array
                        currentObj = currentObj[key.slice(0, key.indexOf('['))][index];
                    }
                }
            } else {
                if (i === keys.length - 1) {
                    // Last key, set the value
                    currentObj[key] = value;
                } else {
                    // Traverse deeper into the object
                    currentObj[key] = currentObj[key] || {};
                    currentObj = currentObj[key];
                }
            }
        }
    }

    /**
     * Splits a string into an array of substrings based on the period ('.') character,
     * except when the period is within square brackets ('[]').
     *
     * @param input The input string to be split.
     * @returns An array of substrings split based on the provided criteria.
     */
    private static splitPath(input: string): string[] {
        const result: string[] = [];
        let temp = '';
        let withinBrackets = false;

        for (let i = 0; i < input.length; i++) {
            const char = input[i];

            if (char === '[') {
                withinBrackets = true;
                temp += char;
            } else if (char === ']') {
                withinBrackets = false;
                temp += char;
            } else if (char === '.' && !withinBrackets) {
                result.push(temp);
                temp = '';
            } else {
                temp += char;
            }
        }

        if (temp !== '') {
            result.push(temp);
        }

        return result;
    }

    /**
     * Takes a path with a "." separator and any object and retrieves the value at the end of the path. E.g. the path
     * might be "path.to.data.array", so the property "array" nested in "data" nested in "to" nested in "path" will be
     * retrieved from the obj. If the path is not found undefined is returned.
     *
     * @param path The input string representing the path, e.g. "foo.bar[1].hello"
     * @param obj The object that should contain the path
     * @param options
     * @returns Value at the end of the path or undefined
     */
    public static getValueByPath(path: string, obj: any, options?: { skipWarnings?: boolean }): any {
        if (path == '') {
            return obj;
        }
        const pathArray = DataUtils.splitPath(path); //path.split('.');
        let currentObj = obj;

        for (const segment of pathArray) {
            if (segment.includes('[') && segment.includes(']')) {
                const key = segment.substring(0, segment.indexOf('['));
                const indexExp = segment.substring(segment.indexOf('[') + 1, segment.indexOf(']'));
                if (indexExp.includes("?")) {
                    const [attribute, value] = indexExp.split("?");
                    let foundIndex = false;
                    if (key === '' && Array.isArray(currentObj)) {
                        for (let i = 0; i < currentObj.length; i++) {
                            const compValue = DataUtils.getValueByPath(attribute, currentObj[i]) + "";
                            if (compValue === value) {
                                currentObj = currentObj[i];
                                foundIndex = true;
                                break;
                            }
                        }
                    } else if (key in currentObj && Array.isArray(currentObj[key])) {
                        for (let i = 0; i < currentObj[key].length; i++) {
                            const compValue = DataUtils.getValueByPath(attribute, currentObj[key][i]) + "";
                            if (compValue === value) {
                                currentObj = currentObj[key][i];
                                foundIndex = true;
                                break;
                            }
                        }
                    }
                    if (!foundIndex) {
                        if (options?.skipWarnings !== true) {
                            log.warn(`Unable to find index for object with attribute '${attribute}' with value '${value}' in object '${JSON.stringify(obj)}'`);
                        }
                        return undefined;
                    }
                } else {
                    const index = parseInt(segment.substring(segment.indexOf('[') + 1, segment.indexOf(']')));

                    if (key === '' && Array.isArray(currentObj)) {
                        currentObj = currentObj[index];
                    } else if (key in currentObj && Array.isArray(currentObj[key])) {
                        currentObj = currentObj[key][index];
                    } else {
                        if (options?.skipWarnings !== true) {
                            log.warn(`Path '${path}' not found in object '${JSON.stringify(obj)}'`);
                        }
                        return undefined;
                    }
                }
            } else {
                if (currentObj[segment] !== undefined) {
                    currentObj = currentObj[segment];
                } else {
                    if (options?.skipWarnings !== true) {
                        log.warn(`Path '${path}' not found in object '${JSON.stringify(obj)}'`);
                    }
                    return undefined;
                }
            }
        }

        return currentObj;
    }

    /**
     * Recursively finds and collects all values of attributes with a specified name within an object and its
     * sub-objects.
     *
     * @param obj - The object to search within.
     * @param targetAttribute - The name of the attribute to find.
     * @param collectedValues - The array to store the found attribute values.
     */
    public static collectAttributeValues(obj: any, targetAttribute: string, collectedValues: any[] = []): any[] {
        // Check if the input is actually an object or an array
        if (obj && typeof obj === 'object') {
            if (Array.isArray(obj)) {
                // Iterate over each item in the array
                for (const item of obj) {
                    // Recursively search each item if it's an object
                    if (item && typeof item === 'object') {
                        DataUtils.collectAttributeValues(item, targetAttribute, collectedValues);
                    }
                }
            } else {
                // Iterate over each key in the object
                for (const key in obj) {
                    if (key in obj) {
                        const value = obj[key];

                        // Check if the key matches the target attribute name
                        if (key === targetAttribute) {
                            collectedValues.push(value);
                        }

                        // If the value is an object or an array, recurse into it
                        if (value && typeof value === 'object') {
                            DataUtils.collectAttributeValues(value, targetAttribute, collectedValues);
                        }
                    }
                }
            }
        }

        return collectedValues;
    }

    /**
     * Takes any object and a replacement matrix and iterates over each value in the object. If there are any string
     * values, they are searched for target values in the replacement matrix and if found replaced with the defined
     * value in the replacement matrix. A refSource can be passed which could be necessary for some replacement getters
     * (e.g. generic wildcards like {{ZM_REF[***]}}).
     *
     * @param obj is any object the matrix should be applied to
     * @param replacementMatrix is the matrix with the target - getter pairs
     * @param refSource is the object containing any referenced values used in the matrix
     * @returns the obj with targets from the matrix replaced with the values retrieved from the getters
     */
    public static replaceByMatrix<T>(obj: T, replacementMatrix: i_replacement[], refSource?: any): T {
        if (refSource === undefined) {
            refSource = {};
        }
        if (typeof obj === 'string') {
            let newObj: any = obj;
            // process static wildcards first (e.g. {{ZM_H>)
            const staticWildcardsRegex = /\{\{(ZM_[A-Z_]+?)}}/g
            let matchStaticWildcard = staticWildcardsRegex.exec(newObj);
            const matchesStaticWildcards: RegExpExecArray[] = [];
            while (matchStaticWildcard !== null) {
                matchesStaticWildcards.push(matchStaticWildcard);
                matchStaticWildcard = staticWildcardsRegex.exec(newObj);
            }
            for (const match of matchesStaticWildcards) {
                const rep = replacementMatrix.filter(rep_ => rep_.target === match[1]);
                if (rep.length > 0) {
                    if (`{{${match[1]}}}` === newObj) {
                        // exact match, do type conversion if necessary
                        newObj = rep[0].getter();
                    } else {
                        newObj = newObj.split(`{{${match[1]}}}`).join(rep[0].getter());
                    }
                } else {
                    log.warn(`Unknown static wildcard '${match[1]}' will be ignored!`);
                }
            }
            // process content wildcards (e.g. {{ZM_REF[...]}})
            let currentIterations = 0;
            do {
                const contentWildcardRegex = /\{\{(ZM_[A-Z_]+?)\[((?:(?!ZM_[A-Z_]+?\[).)*?)]}}/g;
                let matchContentWildcard = contentWildcardRegex.exec(newObj);
                const matchesContentWildcard: RegExpExecArray[] = [];
                while (matchContentWildcard !== null) {
                    matchesContentWildcard.push(matchContentWildcard);
                    matchContentWildcard = contentWildcardRegex.exec(newObj);
                }
                //log.debug(`Found ${matchesContentWildcard.length} content wildcards!`);
                for (const match of matchesContentWildcard) {
                    const rep = replacementMatrix.filter(rep_ => rep_.target === match[1]);
                    if (rep.length > 0) {
                        if (`{{${match[1]}[${match[2]}]}}` === newObj) {
                            // exact match, do type conversion if necessary
                            newObj = rep[0].getter(match[2], refSource);
                        } else {
                            newObj = newObj.split(`{{${match[1]}[${match[2]}]}}`).join(rep[0].getter(match[2], refSource));
                        }
                    } else {
                        log.warn(`Unknown content wildcard '${match[1]}' will be ignored!`);
                    }
                }
                currentIterations += 1;
                if (contentWildcardRegex.exec(newObj) === null) {
                    currentIterations = this.MAX_WILDCARD_ITERATIONS;
                    //log.debug("All content wildcards were processed: " + newObj);
                } else if (currentIterations >= this.MAX_WILDCARD_ITERATIONS) {
                    log.warn("Exceeded maximum iterations for wildcard iteration: " + newObj);
                }
            } while(currentIterations < this.MAX_WILDCARD_ITERATIONS);
            return newObj;
        } else if (typeof obj === 'object') {
            if (Array.isArray(obj)) {
                const newObj: any = [];
                for (const element of obj) {
                    newObj.push(DataUtils.replaceByMatrix(element, replacementMatrix, refSource))
                }
                return newObj;
            } else {
                const newObj: any = {};
                for (const key in obj) {
                    if (key in obj) {
                        newObj[key] = DataUtils.replaceByMatrix(obj[key], replacementMatrix, refSource);
                    }
                }
                return newObj;
            }
        } else {
            return obj;
        }
    }

    public static unproxy<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj)) as T;
    }

    /**
     * Returns true if the color is considered bright.
     *
     * @param color
     */
    public static isColorBright(color: string | [number, number, number]): boolean {
        return DataUtils.isInLuminanceRange(color, 180, 999);
    }

    /**
     * Checks if a the luminance of a color is within a certain range.
     *
     * @param color color in any format (hex string or rgb)
     * @param min lower bound (0 being white)
     * @param max upper bound (255 being black)
     */
    public static isInLuminanceRange(color: string | [number, number, number], min: number, max: number): boolean {
        // Calculate luminance using the formula: L = 0.2126*R + 0.7152*G + 0.0722*B
        const [red, green, blue] = DataUtils.getRgbColor(color);
        const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

        // Check if luminance is greater than a threshold (here 128) to determine if the color is bright
        return luminance >= min && luminance <= max;
    }

    public static getRgbColor(color: string | [number, number, number]): [number, number, number] {
        if (typeof color === 'string' && color.startsWith('#')) {
            let hex = color.substring(1);
            // Handle shorthand hex codes (#FFF)
            if (hex.length === 3) {
                hex = hex.split('').map(char => char + char).join('');
            }
            return [
                parseInt(hex.substring(0, 2), 16),
                parseInt(hex.substring(2, 4), 16),
                parseInt(hex.substring(4, 6), 16)
            ];
        } else if (typeof color === 'string') {
            // Convert descriptive color string to RGB
            return DataUtils.getRgbColor(StringUtils.colorNameToHex(color));
        } else {
            // Assume RGB array is provided directly
            return color;
        }
    }

    public static getColorWithOpacity(color: string | [number, number, number], opacity: number) {
        const _color = this.getRgbColor(color);
        return [ ..._color, opacity ];
    }

    static getHslColor(color: string | [number, number, number]): [number, number, number] {
        if (typeof color === "string") {
            color = DataUtils.getRgbColor(color);
        }
        const r = color[0] / 255;
        const g = color[1] / 255;
        const b = color[2] / 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h: number = 0;
        let s: number;
        const l: number = (max + min) / 2;

        if (max === min) {
            h = 0;
            s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return [h * 360, s, l];
    }

    /**
     * The Haversine formula is used to calculate the distance between two points on the Earth's surface.
     *
     * @param coord1
     * @param coord2
     */
    public static haversineDistance(coord1: i_position, coord2: i_position): number {
        const toRadians = (degrees: number) => degrees * Math.PI / 180;

        const R = 6371; // Radius of the Earth in kilometers
        const dLat = toRadians(coord2.lat - coord1.lat);
        const dLng = toRadians(coord2.lng - coord1.lng);
        const lat1 = toRadians(coord1.lat);
        const lat2 = toRadians(coord2.lat);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    }

    /**
     * Checks if the given object contains any parameterized wildcards or conditional values.
     *
     * This function recursively traverses the input object to determine if any string values
     * match a predefined set of parameterized wildcards or if any part of the object is a conditional value.
     *
     * @param {any} obj - The object to be checked for wildcards or conditionals.
     * @returns {boolean} - Returns `true` if the object contains any of the specified wildcards or conditionals, otherwise returns `false`.
     */
    public static containsWildcardOrConditional(obj: any): boolean {
        // Convert parameterized wildcards to a set for quick lookup
        const parameterizedWildcards = new Set([
            `{{${DATA_WILDCARDS.ZM_CALC}\\[`,
            `{{${DATA_WILDCARDS.ZM_REF}\\[`,
            `{{${DATA_WILDCARDS.ZM_EVAL}\\[`,
            `{{${DATA_WILDCARDS.ZM_ROUND}\\[`
        ]);

        // Create a regex pattern that matches any of the wildcards
        const wildcardPattern = new RegExp([...parameterizedWildcards].join('|'));

        // Helper function to recursively check the object
        const search = (value: any): boolean => {
            if (typeof value === 'string' && wildcardPattern.test(value)) {
                return true;
            }

            if (isConditional(value)) {
                return true;
            }

            if (typeof value === 'object' && value !== null) {
                // Check each property in the object or each element in the array
                for (const key in value) {
                    if (key in value && search(value[key])) {
                        return true;
                    }
                }
            }

            return false;
        }

        return search(obj);
    }

    public static createLangRefs() {
        const rec: Record<LANGUAGE, Ref<string | undefined>> = {} as Record<LANGUAGE, Ref<string | undefined>>;
        for (const l of Object.values(LANGUAGE)) {
            rec[l] = ref();
        }
        return rec;
    }

    public static langRefsToText(langRefs: Record<LANGUAGE, Ref<string | undefined>>): i_text {
        const obj: any = {};
        Object.keys(langRefs).forEach((key: string) => {
            obj[key] = langRefs[key as LANGUAGE].value;
        })
        return obj as i_text;
    }
}
