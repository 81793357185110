import {Log} from "@/utils/log";
import {i_aggregate, i_from, i_join, i_mapping, t_operation} from "@/types/interfaces/structure.interfaces";
import {isAggregate, isFrom, isJoin, isRequest} from "@/types/typeguards/general.guards";
import {FetchWorkerUtils} from "@/utils/fetch.worker.utils";
import {DataUtils} from "@/utils/data.utils";
import {MAPPING_TYPE} from "@/types/enums/general.enums";
import {
    IProxyAggregate,
    IProxyFrom,
    IProxyJoin,
    IProxyRequest,
    TProxyOperation
} from "@/types/interfaces/proxy.interfaces";
import {
    isProxyAggregate,
    isProxyFrom,
    isProxyJoin,
    isProxyOperation,
    isProxyRequest
} from "@/types/typeguards/proxy.guards";
import {IStatic} from "@/types/interfaces/dto.interfaces";
import {isStatic} from "@/types/typeguards/dto.guards";

const log = new Log("OperationUtils");

export class OperationUtils {
    public static join(operation: IProxyJoin): Promise<any> {
        const opPromises = Promise.all(operation.join.map(o => {
            return isProxyOperation(o) ? this.applyOperation(o) : this.fetchStaticOrRequest(o);
        }));
        return opPromises.then(dataArrays => {
            if (dataArrays.length !== operation.on.length) {
                throw new Error("The number of arrays and keys must be the same");
            }

            // Initialize a map to store the joined results
            const joinedMap: Map<string, any> = new Map();

            // Iterate over the arrays and their corresponding keys
            dataArrays.forEach((array, index) => {
                const joinKey = operation.on[index];
                array.forEach((item: any) => {
                    const keyValue = DataUtils.getValueByPath(joinKey, item); // item[joinKey];

                    if (!joinedMap.has(keyValue)) {
                        // Initialize an object with a combined key and other properties
                        joinedMap.set(keyValue, { joinId: keyValue });
                    }

                    // Merge the current item into the existing object in the map
                    Object.keys(item).forEach(key => {
                        joinedMap.get(keyValue)[key] = item[key];
                    });
                });
            });

            // Convert the map values to an array of objects
            return Array.from(joinedMap.values());
        });
    }

    public static fetchStaticOrRequest(staticOrRequest: IStatic | IProxyRequest) {
        return isStatic(staticOrRequest) ? new Promise((resolve) => resolve(staticOrRequest.data)) : FetchWorkerUtils.fetchRequest(staticOrRequest);
    }

    public static aggregate(operation: IProxyAggregate): Promise<any> {
        const opPromise = isProxyOperation(operation.aggregate) ? this.applyOperation(operation.aggregate) : this.fetchStaticOrRequest(operation.aggregate);
        return opPromise.then(data => {
            if (Array.isArray(data)) {
                const grouped: any = {};

                data.forEach(item => {
                    // Extract the key's value to group by
                    const keyValue = DataUtils.getValueByPath(operation.on, item);

                    if (!grouped[keyValue]) {
                        grouped[keyValue] = {};
                    }

                    for (const k in item) {
                        grouped[keyValue][k] = operation.mappings && operation.mappings.map((om: i_mapping) => om.key).includes(k) ?
                            OperationUtils.applyMapping(
                                operation.mappings.filter((om: i_mapping) => om.key === k)[0],
                                item[k],
                                grouped[keyValue][k]
                            ) : (grouped[keyValue][k] ? [ ...grouped[keyValue][k], item[k] ] : [item[k]]);
                    }
                });

                log.debug(JSON.stringify(Object.keys(grouped).map(keyValue => {
                    return {
                        aggregateId: keyValue,
                        ...grouped[keyValue]
                    };
                })));

                // Convert the grouped object back to an array format
                return Object.keys(grouped).map(keyValue => {
                    return {
                        aggregateId: keyValue,
                        ...grouped[keyValue]
                    };
                });
            } else {
                log.error("Invalid aggregation data, no array detected!");
            }
        });
    }

    private static applyMapping(mapping: i_mapping, item: any, current?: any) {
        switch (mapping.type) {
            case MAPPING_TYPE.ADD:
                return current ? current + Number(item) : Number(item);
            case MAPPING_TYPE.MULTIPLY:
                return current ? current * Number(item) : Number(item);
            case MAPPING_TYPE.SUBTRACT:
                return current ? current - Number(item) : -Number(item);
            case MAPPING_TYPE.APPEND:
                return current ? current + item.toString() : item.toString();
            case MAPPING_TYPE.REMOVE:
                return undefined;
            case MAPPING_TYPE.CUSTOM:
                if (mapping.template) {
                    const processedItem = DataUtils.replaceByMatrix(mapping.template, DataUtils.REP_MATRIX, item);
                    return current ? [ ...current, processedItem ] : [ processedItem ];
                } else {
                    log.error(`No template was given for mapping of item ${JSON.stringify(item)}!`);
                    return current ? [ ...current, item ] : [ item ];
                }
            default:
                return current ? [ ...current, item ] : [ item ];
        }
    }

    public static from(operation: IProxyFrom): Promise<any> {
        const opPromise = isProxyOperation(operation.from) ? this.applyOperation(operation.from) : this.fetchStaticOrRequest(operation.from);
        return opPromise.then(data => {
            return DataUtils.getValueByPath(operation.on, data);
        });
    }

    public static applyOperation(operation: TProxyOperation): Promise<any> {
        if (isProxyJoin(operation)) {
            return this.join(operation);
        }
        if (isProxyAggregate(operation)) {
            return this.aggregate(operation);
        }
        if (isProxyFrom(operation)) {
            return this.from(operation);
        }
        return new Promise(() => {
            return { };
        })
    }
}
