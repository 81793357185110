import {_COLORS} from "@/assets/colors";
import {i_parseExpression} from "@/types/interfaces/structure.interfaces";

export class StringUtils {
    /**
     * Adds leading zeros to a number up to a certain length and returns it as a string. E.g. padz(4,3) would return "004".
     *
     * @param number The number to be padded
     * @param size The length to which the number should be extended with zeros
     * @returns String containing the number padded with zeros
     */
    public static padz(number: number, size: number): string {
        let num = number.toString();
        while(num.length < size) {
            num = "0" + num;
        }
        return num;
    }

    /**
     * Checks if the string is an url.
     *
     * @param string The string to be checked
     * @returns true if string is a valid url, otherwise false
     */
    public static isURL(string: any): boolean {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    /**
     * Evaluates a string an executes java script from the string.
     *
     * @param exp is a js expression (e.g. "(true == true) ? 1 : 2")
     * @returns the value returned by the evaluated expression
     */
    public static evaluateExp(exp: string) {
        return eval(exp.replace(/\bAND\b/g, '&&').replace(/\bOR\b/g, '||'));
    }

    /**
     * Creates a string representation of an expression that can be evaluated using `eval()`.
     *
     * This function supports both arithmetic operations (e.g., `+`, `-`, `*`, `/`)
     * and comparison operations (e.g., `==`, `===`, `!=`, `!==`, `<`, `<=`, `>`, `>=`).
     * It ensures proper formatting of operands, such as wrapping strings in quotes,
     * and validates the operator to prevent invalid expressions.
     *
     * @param {any} operand1 - The first operand in the expression. Accepts strings, numbers, or booleans.
     * @param {string} operator - The operator for the expression. Must be one of the following: `+`, `-`, `*`, `/`, `==`, `===`, `!=`, `!==`, `<`, `<=`, `>`, `>=`.
     * @param {any} operand2 - The second operand in the expression. Accepts strings, numbers, or booleans.
     * @param options - Optional parameter to explicitly set the operand types
     *
     * @returns {string} A properly formatted string representing the expression.
     *
     * @throws {Error} If the operator is not valid or if an operand is of an unsupported type.
     */
    public static createExpression(operand1: any, operator: string, operand2: any, options?: { typeOperand1: "number" | "string" | "boolean", typeOperand2: "number" | "string" | "boolean" }): string {
        const validOperators = ['+', '-', '*', '/', '==', '===', '!=', '!==', '<', '<=', '>', '>='];

        if (!validOperators.includes(operator)) {
            throw new Error(`Invalid operator. Supported operators are: ${validOperators.join(', ')}`);
        }

        // Format operands properly
        const formatOperand = (operand: any, type?: "number" | "string" | "boolean"): string => {
            if (type === "string") {
                return `"${operand}"`;
            } else if (type === "number" || type === "boolean") {
                return operand.toString();
            } else if (typeof operand === 'string') {
                // Wrap strings in double quotes
                return `"${operand}"`;
            } else if (typeof operand === 'number' || typeof operand === 'boolean') {
                // Return numbers and booleans as is
                return operand.toString();
            } else {
                // Unsupported types
                throw new Error(`Unsupported operand type: ${typeof operand}`);
            }
        };

        const formattedOperand1 = formatOperand(operand1, options?.typeOperand1);
        const formattedOperand2 = formatOperand(operand2, options?.typeOperand2);

        // Create the expression as a string
        return `${formattedOperand1} ${operator} ${formattedOperand2}`;
    }

    /**
     * Parses a mathematical or logical expression string and extracts the operands and operator.
     *
     * This function supports arithmetic operators (e.g., `+`, `-`, `*`, `/`) and comparison operators
     * (e.g., `==`, `===`, `!=`, `!==`, `<`, `<=`, `>`, `>=`). It also handles quoted string operands
     * and trims spaces if present in the expression.
     *
     * @param {string} expression - The expression string to parse.
     * @returns {{ operand1: any, operator: string, operand2: any }} An object containing the operands and the operator.
     *
     * @throws {Error} If the expression is invalid or cannot be parsed.
     */
    public static parseExpression(expression: string): i_parseExpression {
        const validOperators = ['==', '!=', '<', '<=', '>', '>='];

        // Regular expression to capture operand1, operator, and operand2
        const regex = /(["'].*?["']|\S+)\s*(==|!=|<=|>=|<|>)\s*(["'].*?["']|\S+)/;

        // Match the expression
        const match = expression.match(regex);
        if (!match) {
            throw new Error(`Invalid expression: ${expression}`);
        }

        const [, operand1, operator, operand2] = match;

        // Validate the operator
        if (!validOperators.includes(operator)) {
            throw new Error(`Invalid operator found: ${operator}`);
        }

        return { operand1, operator, operand2 };
    }


    /**
     * Creates a duration string in a human-readable format (e.g. "1h 20min").
     *
     * @param duration is a numeric duration in milliseconds
     * @returns a string containing the duration information
     */
    public static fromDuration(duration: number) {
        const durationHours = Math.floor(duration/(60*60*1000));
        const durationMinutes = Math.floor((duration - durationHours * (60*60*1000))/(60*1000));
        return durationHours > 0 ? `${durationHours}h ${durationMinutes}min` : `${durationMinutes < 1 ? '<1' : durationMinutes}min`
    }

    /**
     * Converts a colorname to a hex color.
     *
     * @param name is the name of the color, e.g. ("black", "green", "blue"...)
     */
    public static colorNameToHex(name: string): string {
        if (Object.keys(_COLORS).includes(name.toLowerCase())) {
            return (_COLORS as any)[name.toLowerCase()];
        } else {
            if (name.charAt(0) === "#") {
                return name;
            }
            return _COLORS.black;
        }
    }

    /**
     * Checks if a potential string is a valid hex color.
     *
     * @param str - The potential string to check.
     * @returns True if the string is a valid hex color, otherwise false.
     */
    public static isHexColor(str: string | undefined | null): boolean {
        if (str === undefined || str === null) {
            return false;
        }
        // Regular expression to match valid hex color codes
        const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/;
        return hexColorRegex.test(str);
    }

    public static buildZmRef(path: string) {
        return `{{ZM_REF[${path}]}}`
    }

    public static isZmRef(value: any) {
        return typeof value === "string" && value.includes("{{ZM_REF[");
    }

    public static extractZmRef(zmRef: string) {
        return zmRef.split("{{ZM_REF[")[1].split("]}}")[0];
    }
}
