export const _ICONS = [
    "account-tie-hat",
    "account-tie-hat-outline",
    "airballoon",
    "airballoon",
    "airballoon-outline",
    "airplane",
    "airplane-alert",
    "airplane-check",
    "airplane-clock",
    "airplane-cog",
    "airplane-edit",
    "airplane-landing",
    "airplane-marker",
    "airplane-minus",
    "airplane-off",
    "airplane-plus",
    "airplane-remove",
    "airplane-search",
    "airplane-settings",
    "airplane-takeoff",
    "airport",
    "airport",
    "ambulance",
    "atv",
    "aurora",
    "bag-carry-on",
    "bag-carry-on-check",
    "bag-carry-on-off",
    "bag-checked",
    "bag-personal",
    "bag-personal-off",
    "bag-personal-off-outline",
    "bag-personal-outline",
    "bag-suitcase",
    "bag-suitcase-off",
    "bag-suitcase-off-outline",
    "bag-suitcase-outline",
    "balloon",
    "bank",
    "bat",
    "beach",
    "bed",
    "bed-double",
    "bed-double-outline",
    "bed-empty",
    "bed-king",
    "bed-king-outline",
    "bed-outline",
    "bed-queen",
    "bed-queen-outline",
    "bed-single",
    "bed-single-outline",
    "bicycle",
    "bicycle-basket",
    "bicycle-cargo",
    "bicycle-electric",
    "bicycle-penny-farthing",
    "bike",
    "bike-fast",
    "bike-pedal",
    "bike-pedal-clipless",
    "bike-pedal-mountain",
    "bone",
    "bone-off",
    "boom-gate",
    "boom-gate-alert",
    "boom-gate-alert-outline",
    "boom-gate-arrow-down",
    "boom-gate-arrow-down-outline",
    "boom-gate-arrow-up",
    "boom-gate-arrow-up-outline",
    "boom-gate-outline",
    "boom-gate-up",
    "boom-gate-up-outline",
    "bottle-tonic-skull",
    "bottle-tonic-skull-outline",
    "bridge",
    "broadcast",
    "broadcast-off",
    "bus",
    "bus-alert",
    "bus-articulated-end",
    "bus-articulated-front",
    "bus-clock",
    "bus-double-decker",
    "bus-electric",
    "bus-multiple",
    "bus-school",
    "bus-side",
    "bus-stop",
    "bus-stop-covered",
    "bus-stop-uncovered",
    "cake",
    "cake-layered",
    "cake-variant",
    "cake-variant-outline",
    "candelabra",
    "candelabra-fire",
    "candle",
    "candycane",
    "car",
    "car-2-plus",
    "car-3-plus",
    "car-arrow-left",
    "car-arrow-right",
    "caravan",
    "car-back",
    "car-cog",
    "car-connected",
    "car-convertible",
    "cards-diamond",
    "cards-diamond-outline",
    "car-electric",
    "car-electric-outline",
    "car-emergency",
    "car-estate",
    "car-hatchback",
    "car-key",
    "car-limousine",
    "car-multiple",
    "car-pickup",
    "car-side",
    "car-sports",
    "car-wash",
    "car-wash",
    "castle",
    "cat",
    "church",
    "church-outline",
    "city",
    "city-switch",
    "city-variant",
    "city-variant-outline",
    "cloud",
    "cloud-alert",
    "cloud-alert-outline",
    "cloud-arrow-down",
    "cloud-arrow-down-outline",
    "cloud-arrow-left",
    "cloud-arrow-left-outline",
    "cloud-arrow-right",
    "cloud-arrow-right-outline",
    "cloud-arrow-up",
    "cloud-arrow-up-outline",
    "cloud-cancel",
    "cloud-cancel-outline",
    "cloud-check",
    "cloud-check-outline",
    "cloud-check-variant",
    "cloud-check-variant-outline",
    "cloud-circle",
    "cloud-circle-outline",
    "cloud-clock",
    "cloud-clock-outline",
    "cloud-cog",
    "cloud-cog-outline",
    "cloud-download",
    "cloud-download-outline",
    "cloud-off",
    "cloud-off-outline",
    "cloud-outline",
    "cloud-percent",
    "cloud-percent-outline",
    "cloud-question",
    "cloud-question-outline",
    "cloud-refresh",
    "cloud-refresh-outline",
    "cloud-refresh-variant",
    "cloud-refresh-variant-outline",
    "cloud-remove-outline",
    "clouds",
    "cloud-search",
    "cloud-search-outline",
    "cloud-sync",
    "cloud-sync-outline",
    "cloud-upload",
    "cloud-upload-outline",
    "coffin",
    "cross",
    "cross-celtic",
    "domain",
    "drone",
    "dump-truck",
    "egg-easter",
    "eiffel-tower",
    "elevator",
    "elevator-down",
    "elevator-passenger",
    "elevator-passenger-off",
    "elevator-passenger-off-outline",
    "elevator-passenger-outline",
    "elevator-up",
    "escalator",
    "escalator-down",
    "escalator-up",
    "ev-station",
    "excavator",
    "factory",
    "fire",
    "fire-hydrant",
    "fire-station",
    "fire-truck",
    "firework",
    "flash",
    "flash-alert",
    "flash-alert-outline",
    "flash-outline",
    "food-turkey",
    "forest",
    "forest-outline",
    "forklift",
    "gas-station",
    "generator-mobile",
    "gift",
    "gift-off",
    "gift-off-outline",
    "gift-open",
    "gift-open-outline",
    "gift-outline",
    "golf-cart",
    "gondola",
    "grave-stone",
    "hail",
    "halloween",
    "heat-wave",
    "helicopter",
    "hexagram",
    "hexagram-outline",
    "highway",
    "home",
    "home-city-outline",
    "home-flood",
    "home-outline",
    "horse",
    "horse-human",
    "hospital-building",
    "human-capacity-decrease",
    "human-capacity-increase",
    "human-scooter",
    "island",
    "jeepney",
    "library",
    "library-outline",
    "lightning-bolt",
    "lightning-bolt-circle",
    "lightning-bolt-outline",
    "looks",
    "menorah",
    "menorah-fire",
    "minus-circle",
    "minus-circle-outline",
    "moon-first-quarter",
    "moon-full",
    "moon-last-quarter",
    "moon-new",
    "moon-waning-crescent",
    "moon-waning-gibbous",
    "moon-waxing-crescent",
    "moon-waxing-gibbous",
    "moped",
    "moped",
    "moped-electric",
    "moped-electric-outline",
    "moped-outline",
    "mosque-outline",
    "motorbike",
    "motorbike-electric",
    "motorbike-off",
    "octagon",
    "octagon-outline",
    "office-building",
    "office-building-cog",
    "office-building-cog-outline",
    "office-building-marker",
    "office-building-marker-outline",
    "office-building-outline",
    "ornament",
    "ornament-variant",
    "owl",
    "parachute",
    "parachute-outline",
    "parking",
    "party-popper",
    "pier",
    "pier-crane",
    "pine-tree",
    "pine-tree",
    "pine-tree-box",
    "pine-tree-variant",
    "pine-tree-variant-outline",
    "pizza",
    "plane-car",
    "plane-car",
    "plane-train",
    "plane-train",
    "police-station",
    "pool",
    "pot",
    "pot-mix",
    "pot-mix-outline",
    "pot-outline",
    "pot-steam",
    "pot-steam-outline",
    "pumpkin",
    "rabbit-variant",
    "rabbit-variant-outline",
    "railroad-light",
    "rickshaw",
    "rickshaw",
    "rickshaw-electric",
    "rickshaw-electric",
    "road",
    "road-variant",
    "rocket",
    "rocket-launch",
    "rocket-launch-outline",
    "rocket-outline",
    "rv-truck",
    "scooter",
    "scooter-electric",
    "shield-airplane",
    "shield-airplane-outline",
    "shield-sun",
    "shield-sun-outline",
    "sign-caution",
    "sign-yield",
    "silverware-variant",
    "skull",
    "skull-crossbones",
    "skull-crossbones-outline",
    "skull-outline",
    "snowflake",
    "snowflake",
    "snowflake-alert",
    "snowflake-check",
    "snowflake-melt",
    "snowflake-off",
    "snowflake-thermometer",
    "snowflake-variant",
    "snowflake-variant",
    "snowman",
    "snowmobile",
    "spider",
    "spider-outline",
    "spider-thread",
    "spider-web",
    "stadium",
    "stadium-outline",
    "stadium-variant",
    "stairs",
    "stairs-down",
    "stairs-up",
    "stocking",
    "store",
    "store-24-hour",
    "store-alert",
    "store-alert-outline",
    "store-check",
    "store-check-outline",
    "store-clock",
    "store-clock-outline",
    "store-cog",
    "store-cog-outline",
    "store-edit",
    "store-edit-outline",
    "storefront",
    "storefront-outline",
    "store-marker",
    "store-marker-outline",
    "store-minus",
    "store-minus-outline",
    "store-off",
    "store-off-outline",
    "store-outline",
    "store-plus",
    "store-plus-outline",
    "store-remove",
    "store-remove-outline",
    "store-search",
    "store-search-outline",
    "store-settings",
    "store-settings-outline",
    "subway",
    "subway-alert-variant",
    "subway-variant",
    "sun-angle",
    "sun-angle-outline",
    "sun-clock",
    "sun-clock-outline",
    "sun-compass",
    "sun-snowflake",
    "sun-snowflake-variant",
    "sun-thermometer",
    "sun-thermometer-outline",
    "sun-wireless",
    "sun-wireless-outline",
    "synagogue",
    "synagogue-outline",
    "tanker-truck",
    "taxi",
    "teddy-bear",
    "temperature-celsius",
    "temperature-fahrenheit",
    "temperature-kelvin",
    "temple-buddhist",
    "temple-buddhist-outline",
    "temple-hindu",
    "temple-hindu-outline",
    "theater",
    "theme-light-dark",
    "thermometer",
    "thermometer-alert",
    "thermometer-auto",
    "thermometer-bluetooth",
    "thermometer-check",
    "thermometer-chevron-down",
    "thermometer-chevron-up",
    "thermometer-high",
    "thermometer-lines",
    "thermometer-low",
    "thermometer-minus",
    "thermometer-off",
    "thermometer-plus",
    "thermometer-water",
    "town-hall",
    "tow-truck",
    "tractor",
    "traffic-cone",
    "traffic-light",
    "traffic-light-outline",
    "train",
    "train-car",
    "train-car-autorack",
    "train-car-box",
    "train-car-box-full",
    "train-car-box-open",
    "train-car-caboose",
    "train-car-centerbeam",
    "train-car-centerbeam-full",
    "train-car-container",
    "train-car-flatbed",
    "train-car-flatbed-car",
    "train-car-flatbed-tank",
    "train-car-gondola",
    "train-car-gondola-full",
    "train-car-hopper",
    "train-car-hopper-covered",
    "train-car-hopper-full",
    "train-car-intermodal",
    "train-car-passenger",
    "train-car-passenger-door",
    "train-car-passenger-door-open",
    "train-car-passenger-variant",
    "train-car-tank",
    "train-variant",
    "tram",
    "tram-side",
    "transit-connection",
    "transit-connection-horizontal",
    "transit-connection-variant",
    "transit-detour",
    "transit-skip",
    "transit-transfer",
    "truck",
    "truck-alert",
    "truck-alert-outline",
    "truck-cargo-container",
    "truck-check",
    "truck-check-outline",
    "truck-delivery",
    "truck-delivery-outline",
    "truck-fast",
    "truck-fast-outline",
    "truck-flatbed",
    "truck-minus",
    "truck-minus-outline",
    "truck-off-road",
    "truck-off-road-off",
    "truck-outline",
    "truck-plus",
    "truck-plus-outline",
    "truck-remove",
    "truck-remove-outline",
    "truck-snowflake",
    "truck-trailer",
    "tsunami",
    "tunnel",
    "tunnel",
    "tunnel-outline",
    "tunnel-outline",
    "turbine",
    "turkey",
    "umbrella",
    "umbrella-beach",
    "umbrella-beach-outline",
    "umbrella-closed",
    "umbrella-closed-outline",
    "umbrella-closed-variant",
    "umbrella-outline",
    "unicycle",
    "van-passenger",
    "van-utility",
    "walk",
    "warehouse",
    "water",
    "water-opacity",
    "water-outline",
    "water-percent",
    "water-thermometer",
    "water-thermometer-outline",
    "waves",
    "waves-arrow-left",
    "waves-arrow-right",
    "waves-arrow-up",
    "weather-cloudy",
    "weather-cloudy-alert",
    "weather-cloudy-arrow-right",
    "weather-cloudy-clock",
    "weather-dust",
    "weather-fog",
    "weather-hail",
    "weather-hazy",
    "weather-hurricane",
    "weather-hurricane-outline",
    "weather-lightning",
    "weather-lightning-rainy",
    "weather-moonset",
    "weather-moonset-down",
    "weather-moonset-up",
    "weather-night",
    "weather-night",
    "weather-night-partly-cloudy",
    "weather-partly-cloudy",
    "weather-partly-lightning",
    "weather-partly-rainy",
    "weather-partly-snowy",
    "weather-partly-snowy-rainy",
    "weather-pouring",
    "weather-rainy",
    "weather-snowy",
    "weather-snowy-heavy",
    "weather-snowy-rainy",
    "weather-sunny",
    "weather-sunny-alert",
    "weather-sunny-off",
    "weather-sunset",
    "weather-sunset-down",
    "weather-sunset-up",
    "weather-tornado",
    "weather-windy",
    "weather-windy-variant",
    "white-balance-sunny",
    "windsock",
]
